/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 *
 *
 *--------------------------------------------------------------------------------------------*/

import React from 'react';
import './styles/Card.css';

export interface CardProps {
    title: string;
    id?: string;
    imageUrl: string;
    text?: string;
    href: string;
    loadingText?: string;
    downloadFileName?: string;
}

const Card: React.FC<CardProps> = ({
    title,
    id,
    imageUrl,
    text,
    href,
    loadingText,
    downloadFileName
}) => {
    if (loadingText) {
        return (
            <div className="card">
                <div className="card-title">{title}</div>
                <img src={imageUrl} alt={title} width="100" />
                <div className="card-text">{loadingText}</div>
                <div className="loading-icon" />
            </div>
        );
    }

    if (downloadFileName) {
        return (
            <a className="card" id={id} href={href} download={downloadFileName}>
                <div className="card-title pointer-events">{title}</div>
                <img className='pointer-events' src={imageUrl} alt={title} width="100" />
                <div className="card-text pointer-events">{text}</div>
            </a>
        );
    }

    return (
        <a className="card" href={href} target="_blank" rel="noopener noreferrer" >
            <div className="card-title">{title}</div>
            <img src={imageUrl} alt={title} width="100" />
            {text ? <div className="card-text">{text}</div> : <></>}
        </a>
    )
};

export default Card;
