/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 *
 *
 *--------------------------------------------------------------------------------------------*/

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const OAuthCallback: React.FC = () => {
    const { isLogged, handleCode } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            handleCode(code);
        }
    }, [handleCode]);

    useEffect(() => {
        if (isLogged) {
            navigate('/');
        }
    }, [isLogged, navigate]);

    return (
        <div>
            {/* <h2>Authenticating...</h2> */}
        </div>
    );
};

export default OAuthCallback;
