/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Product Download page.
 *
 *--------------------------------------------------------------------------------------------*/

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../config';
import DownloadLibraries, { LibBundle } from './DownloadLibraries';
import DownloadProduct from './DownloadProduct';
import './styles/Download.css';

const Download: React.FC = () => {
  const { t } = useTranslation();

  const [libBundles, setLibBundles] = useState<LibBundle[]>();

  useEffect(() => {
    fetch(`${config.downloadServer}/${config.availablePacksApi}?from=product`)
      .then(response => response.json())
      .then(data => {
        setLibBundles(data.groups)
      });
  }, []);

  return (
    <Fragment>
        <div className='download-title'>
            {t('downloadPageTitle')}
        </div>
        <div className='forms'>
            <DownloadProduct />
            <DownloadLibraries 
              libBundles={libBundles}
            />
        </div>
    </Fragment>
  );
};

export default Download;
