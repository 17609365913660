/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Component ResizableList.
 *
 *--------------------------------------------------------------------------------------------*/

import React from 'react';
import './styles/ResizableList.css';

export interface ResizableListProps {
    rows: string[];
}

const ResizableList: React.FC<ResizableListProps> = ({ rows }) => {
    return (
        <div className='resizable-list'>
            <ul>
                {rows.map((row, index) => (
                    <li key={index}>{row}</li>
                ))}
            </ul>
        </div>
    );
}

export default ResizableList;
