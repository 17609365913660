
/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Components: Tab TabPanel Tabs
 *
 *--------------------------------------------------------------------------------------------*/

import React, { useState } from 'react';
import './styles/Tab.css';

export interface TabProps {
  title: string;
  children?: React.ReactNode;
}

export const Tab: React.FC<TabProps> = ({ title }) => {
  return (<div className='tab-title'>{title}</div>);
};

export type Tab = typeof Tab;

interface TabsProps {
  value: number;
  children?: React.ReactNode[];
  onChange?: (value: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  value, children, onChange
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(value);

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    if (onChange) {
      onChange(index);
    }
  };

  return (<>
    <div className='tab-button-container'>
      {children?.map((child, index) => {
        return (
          <div
            key={index}
            className={`tab-button ${selectedIndex === index ? 'active' : ''}`}
            onClick={() => handleTabChange(index)}
          >
            {child}
          </div>
        );
      })}
    </div>
  </>);
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className='tab-panel'
      {...other}
    >
      {value === index && children}
    </div>
  );
}
