/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 *
 *
 *--------------------------------------------------------------------------------------------*/

import React, { Fragment } from 'react';
import Card from '../components/Card';

import docIcon from '../assets/docs.png';
import { useTranslation } from 'react-i18next';

const Docs: React.FC = () => {

    const { t } = useTranslation();

    return (
        <Fragment>
            <div className='download-title'>
                {t('docsPageTitle')}
            </div>
            <div className='cards-container'>
                <Card
                    title={t('zstudioDocEn')}
                    imageUrl={docIcon}
                    href='https://docs.terapines.com/zstudio/en/latest/index.html'
                    // text={t('accessEntry')}
                />
                <Card
                    title={t('zstudioDocZh')}
                    imageUrl={docIcon}
                    href='https://docs.terapines.com/zstudio/zh-cn/latest/index.html'
                    // text={t('accessEntry')}
                />
                <Card
                    title={t('libMLDspDoc')}
                    imageUrl={docIcon}
                    href='https://docs.terapines.com/libml-dsp/en/latest/index.html'
                    // text={t('accessEntry')}
                />
                <Card
                    title={t('libMLNnDoc')}
                    imageUrl={docIcon}
                    href='https://docs.terapines.com/libml-nn/en/latest/index.html'
                    // text={t('accessEntry')}
                />
            </div>
        </Fragment>
    );
}

export default Docs;
