/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 *
 *
 *--------------------------------------------------------------------------------------------*/

import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './App.css';
import './pages/styles/Login.css';
import './pages/styles/Products.css';

import Navigator from './components/Navigator';
import Docs from './pages/Docs';
import Download from './pages/Download';
import { AuthProvider } from './utils/AuthContext';
import OAuthCallback from './utils/OAuthCallback';

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <Navigator />
                <div className="main">
                    <Routes>
                        <Route path="/downloads" element={<Download />} />
                        <Route path="/docs" element={<Docs />} />
                        <Route path="/auth/callback" element={<OAuthCallback />} />
                        <Route path="*" element={<Navigate to={'/downloads'} />}/>
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
