/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Library Download page
 *
 *--------------------------------------------------------------------------------------------*/

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../components/SelectedField';
import config from '../config';
import './styles/Download.css';
import LibraryCheckboxList from '../components/LibraryCheckboxList';
import { Tab, TabPanel, Tabs } from '../components/Tab';

export interface DownloadLibrariesProps {
  libBundles: LibBundle[] | undefined;
}
export interface LibBundle {
  name: string;
  versions: Version[];
}

export interface Version {
  version: string;
  march_groups: {
    standard_groups: Pack[];
    vendor_groups: Pack[];
  };
}

export interface Pack {
  marchs: March[];
  name: string;
  translation_key: string;  
}

export interface March {
  mabi: string;
  march: string;
  name: string;  
}

const DownloadLibraries: React.FC<DownloadLibrariesProps> = ({ libBundles }) => {

  const { t } = useTranslation();

  const [libBundle, setLibBundle] = useState<string>();
  const [version, setVersion] = useState<string>();

  const [availableLibBundles, setAvailableLibBundles] = useState<string[]>([]);
  const [availableVersions, setAvailableVersions] = useState<string[]>([]);
  /**
   * List of currently available `standard_group`s. Depends on the currently selected `libBundle` and `version`.
   */
  const [availableStandardGroups, setAvailableStandardGroups] = useState<Pack[]>([]);
  /**
   * List of currently available `vendor_group`s. Depends on the currently selected `libBundle` and `version`.
   */
  const [availableVendorGroups, setAvailableVendorGroups] = useState<Pack[]>([]);

  /**
   * A list of selected states for each `standard_group`.
   */
  const [standardGroupStatus, setStandardGroupStatus] = useState<boolean[]>([]);

  /**
   * Selected `vendor_group`.
   */
  const [vendor, setVendor] = useState<string>('');

  const [selectedTab, setSelectedTab] = useState<number>(0);

  /** Get available bundle versions */
  useEffect(() => {
      if (libBundles) {
        if (libBundles?.length > 0) {
          const libBundleNames = libBundles.map((group: LibBundle) => group.name);
          setAvailableLibBundles(libBundleNames);
          setLibBundle(libBundleNames[0]);

          const defaultLibBundles = libBundles[0];
          if (defaultLibBundles.versions?.length > 0) {
            const versionValues = defaultLibBundles.versions.map((version: Version) => version.version);
            setAvailableVersions(versionValues);
            setVersion(versionValues[0]);

            const defaultVersion = defaultLibBundles.versions[0];
            setAvailableStandardGroups(defaultVersion.march_groups.standard_groups);
            setAvailableVendorGroups(defaultVersion.march_groups.vendor_groups);
            setStandardGroupStatus(defaultVersion.march_groups.standard_groups.map(() => false));
            setVendor('');
          }
        }
      }
  }, [libBundles]);

  const handleLibChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLibBundle = availableLibBundles.find(bundle => bundle === e.target.value);
    setLibBundle(selectedLibBundle);
    if (libBundles) {
      const selectedBundle = libBundles.find((bundle: LibBundle) => bundle.name === selectedLibBundle);
  
      if (selectedBundle && selectedBundle.versions?.length > 0) {
        const versionValues = selectedBundle.versions.map((version: Version) => version.version);
        setAvailableVersions(versionValues);
        setVersion(versionValues[0]);
  
        const defaultVersion = selectedBundle.versions[0];
        setAvailableStandardGroups(defaultVersion.march_groups.standard_groups);
        setAvailableVendorGroups(defaultVersion.march_groups.vendor_groups);
        setStandardGroupStatus(defaultVersion.march_groups.standard_groups.map(() => false));
        setVendor('');
      }
    }
  };

  const handleVersionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVersion(e.target.value);
    if (libBundles) {
      const selectedBundle = libBundles.find((bundle: LibBundle) => bundle.name === libBundle);
      const selectedVersion = selectedBundle?.versions.find((version: Version) => version.version === e.target.value);

      setAvailableStandardGroups(selectedVersion?.march_groups.standard_groups ?? []);
      setAvailableVendorGroups(selectedVersion?.march_groups.vendor_groups ?? []);
      setStandardGroupStatus(selectedVersion?.march_groups.standard_groups.map(() => false) ?? []);
      setVendor('');
    }
  };

  /**
   * This will be called after the `standard_group` checkboxes changed.
   * @param status list of selected states for each `standard_group`
   * @returns Download link for the selected groups.
   */
  const handleStandardGroupsChange = (status: boolean[]) => {
    setStandardGroupStatus(status);
    const bundles = availableStandardGroups.filter((item, index) => status[index] === true).map(item => item.name);
    if (!bundles.length) {
      return undefined;
    }
    return createStandardDownloadUrl(bundles);
  };

  /**
   * This will be called after the `vendor_group` checkboxes changed.
   * `vendor_group`s is a `Radio` list.
   * @param status list of selected states for each `vendor_group`
   * @returns Download link for the selected vendor_group.
   */
  const handleVendorGroupsChange = (status: boolean[]) => {
    let newVendor = '';
    status.some((item, index) => {
      if (item === true) {
        newVendor = availableVendorGroups[index].name;
        return true;
      }
    });
    setVendor(newVendor);
    if (!newVendor.length) {
      return undefined;
    }
    return createVendorDownloadUrl(newVendor);
  };

  const createStandardDownloadUrl = (bundles: string[]) => {
    let url = `${config.downloadServer}/${config.packDownloadApi}?`;
    url += `group_name=${libBundle}`;
    url += `&version=${version}`;
    url += `&architecture=risc-v&operation=download`;
    url += `&bundle_type=standard&bundles=${bundles.join('-')}`;
    return url;
  }

  const createVendorDownloadUrl = (vendor: string) => {
    let url = `${config.downloadServer}/${config.packDownloadApi}?`;
    url += `group_name=${libBundle}`;
    url += `&version=${version}`;
    url += `&architecture=risc-v&operation=download`;
    url += `&bundle_type=vendor&vendor=${vendor}`;
    return url;
  }

  /**
   * TODO: Support to selected architectures.
   */
  const handleArchChange = () => {
  };

  const revertToSelectOption = (options: string []) => {
    return options.map(option => {
      return {
        key: option,
        label: option
      };
    });
  }

  const renderStandardGroups = () => {
    return <LibraryCheckboxList
      key={`${libBundle}:${version}:standard`}
      checkboxes={
        availableStandardGroups.map(({ name, marchs, translation_key }, index) => ({
          id: name,
          label: t(translation_key),
          checked: standardGroupStatus[index],
          details: marchs.map(march => march.name)
          /* TODO: Add description to show. */
        }))
      }
      multiple={true}
      onChange={handleStandardGroupsChange}
    />
  };

  const renderVendorGroups = () => {
    return <LibraryCheckboxList
      key={`${libBundle}:${version}:vendor`}
      checkboxes={
        availableVendorGroups.map(({ name, marchs, translation_key }) => ({
          id: name,
          label: t(translation_key),
          checked: vendor === name,
          details: marchs.map(march => march.name)
          /* TODO: Add description to show. */
        }))
      }
      multiple={false}
      onChange={handleVendorGroupsChange}
    />
  };

  return (
    <Fragment>
      <div className='cards-container'>
        <div className="form">
          <div className="form-select-group">
            <div className="note">
              <div className="note-title">{t('titleSoftwareLibrary')}</div>
              <p className="note-content">{t('libraryNoteContent')}</p>
            </div>
            <SelectField
              label={t('labelForLib')}
              name="library-name"
              value={libBundle}
              options={revertToSelectOption(availableLibBundles)}
              onChange={handleLibChange}
            />
            <SelectField
              label={t('labelForVersion')}
              name="version"
              value={version}
              options={revertToSelectOption(availableVersions)}
              onChange={handleVersionChange}
            />
            <SelectField
              label={t('labelForArch')}
              name="arch"
              value={'RISC-V'}
              /* TODO: Get the options from server when we support more architectures. */
              options={revertToSelectOption(['RISC-V'])}
              onChange={handleArchChange}
            />
          </div>
            <div className='download-tabs-container'>
            <Tabs
              value={selectedTab}
              onChange={index => setSelectedTab(index)}
            >
              <Tab title={t('standard')}></Tab>
              <Tab title={t('vendor')}></Tab>
            </Tabs>
            <TabPanel index={0} value={selectedTab}>
              {renderStandardGroups()}
            </TabPanel>
            <TabPanel index={1} value={selectedTab}>
              {renderVendorGroups()}
            </TabPanel>

          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DownloadLibraries;
