/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Product Download page
 *
 *--------------------------------------------------------------------------------------------*/

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../components/SelectedField';
import config from '../config';
import { useAuth, User } from '../utils/AuthContext';
import './styles/Download.css';

interface ProductBundle {
  label: string;
  key: string;
  isFree: boolean;
}

interface Version {
  label: string;
  key: string;
}

interface Platform {
  label: string;
  key: string;
}

const DownloadProduct: React.FC = () => {
  const { isLogged, login, user, accessToken } = useAuth();

  const { t } = useTranslation();

  const [productBundle, setProductBundle] = useState<ProductBundle>();
  const [versionKey, setVersionKey] = useState<string>();
  const [platform, setPlatform] = useState<string>();

  const [availableVersions, setAvailableVersions] = useState<Version[]>([]);
  const [availableProductBundles, setAvailableProductBundles] = useState<ProductBundle[]>([]);
  const [availablePlatforms, setAvailablePlatforms] = useState<Platform[]>([]);

  const [isProductBundleAvailable, setIsProductBundleAvailable] = useState<boolean>(false);
  const [isInWhitelist, setIsInWhitelist] = useState<boolean>(true);

  const [downloadHref, setDownloadHref] = useState<string>('#');

  const toEmailDomain = (email: string) => {
    const domain = email.split('@')[1];
    return domain.replaceAll('.', '_');
  };

  useEffect(() => {
    const checkFileAvailable = async (
      productBundle: string,
      versionKey: string,
      platform: string,
      user?: User,
      token?: string,
    ) => {
      try {
        let url = `${config.downloadServer}/${config.fileDownloadApi}?product_bundle=${productBundle}&version_key=${versionKey}&platform=${platform}&with_libml=1`

        if (user && token) {
          url = url + `&email_domain=${toEmailDomain(user.email)}&token=${token}`
        }

        const checkUrl = url + '&operation=check'
        const response = await fetch(checkUrl)

        const result = await response.json();
        if (response.status === 200) {

          if (result.Message === 'File is accessible') {
            setIsProductBundleAvailable(true);
            const downloadUrl = url + '&operation=download'
            setDownloadHref(downloadUrl)
          } else if (result.Message === 'The product bundle is generating.') {
            setIsProductBundleAvailable(false);
          }
          setIsInWhitelist(true);
        } else if (response.status === 400) {
          setIsInWhitelist(false);
          setIsProductBundleAvailable(false);
        }
      } catch (error) {
        console.error('Failed to check file availability:', error);
      }
    };

    // let intervalId: string | number | NodeJS.Timer | undefined;

    if (productBundle && versionKey && platform) {
      // // Call immediately once and then set the interval
      // checkFileAvailable(productBundle.key, versionKey, platform, user, accessToken).then(() => {
      //   intervalId = setInterval(() => {
      //     checkFileAvailable(productBundle.key, versionKey, platform, user, accessToken);
      //   }, 60 * 1000);  // Check every minute
      // });
      checkFileAvailable(productBundle.key, versionKey, platform, user, accessToken)
    }
    // // Cleanup interval on unmount
    // return () => {
    //   if (intervalId) {
    //     clearInterval(intervalId);
    //   }
    // };

  }, [productBundle, versionKey, platform, isLogged, user, accessToken, isProductBundleAvailable]);

  /** Get available bundle versions */
  useEffect(() => {
    fetch(`${config.downloadServer}/${config.availableVersionsApi}`)
      .then(response => response.json())
      .then(data => {
        if (data.versions?.length > 0) {
          setAvailableVersions(data.versions);
          setVersionKey(data.versions[0].key);
        }

        if (data.platforms?.length > 0) {
          setAvailablePlatforms(data.platforms);
          setPlatform(data.platforms[0].key)
        }

        if (data.productBundles?.length > 0) {
          setAvailableProductBundles(data.productBundles);
          setProductBundle(data.productBundles[0]);
        }
      });
  }, []);

  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProductBundle = availableProductBundles.find(bundle => bundle.key === e.target.value);
    if (selectedProductBundle) {
      setProductBundle(selectedProductBundle);
    }
  };

  const handleProductVersionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setVersionKey(e.target.value);
  };

  const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPlatform(e.target.value);
  };

  const getSubmitButton = () => {
    if (!productBundle?.isFree) {
      if (!isLogged) {
        return <button className='submit-button' type="button" onClick={login} title={t('login')}>{t('login')}</button>
      }

      if (isInWhitelist) {
        return (<>
          <a href={downloadHref} target="_blank" rel="noopener noreferrer" className="submit-a">
            <button type="submit" className="submit-button" disabled={!isProductBundleAvailable} title={ isProductBundleAvailable ? t('download') : t('generating') }>
              {!isProductBundleAvailable && <div className="loading-circle" />}
              { isProductBundleAvailable ? t('download') : t('generating') }
            </button>
          </a>
          {isProductBundleAvailable && getMD5Button()}
        </>)
      } else {
        return (
          <a href={config.contactUsUrl} target="_blank" rel="noopener noreferrer" className="submit-a">
            <button type="submit" className="submit-button" title={t('contactUs')}>
              {t('contactUs')}
            </button>
          </a>
        )
      }
    }

    return (
      <Fragment>
        <a href={downloadHref} target="_blank" rel="noopener noreferrer" className="submit-a">
          <button type="submit" className="submit-button" disabled={!isProductBundleAvailable}>
            {t('download')}
          </button>
        </a>
        {getMD5Button()}
      </Fragment>
    )
  };

  const getMD5Button = () => {
    return (
      <a href={downloadHref + '&md5=true'} target="_blank" rel="noopener noreferrer" className="submit-a">
        <button type="submit" className="submit-button" >
          {t('download-md5')}
        </button>
      </a>
    );
  }

  return (
    <Fragment>
      <div className='cards-container' >
        <div className="form">
          <div className="form-select-group">
            <div className="note">
              <div className="note-title">{t('titleIdeToolchain')}</div>
              <p className="note-content">{t('noteContent')}</p>
            </div>
            <SelectField
              label={t('labelForProduct')}
              name="product"
              value={productBundle?.key}
              options={availableProductBundles}
              onChange={handleProductChange}
            />
            <SelectField
              label={t('labelForVersion')}
              name="version"
              value={versionKey}
              options={availableVersions}
              onChange={handleProductVersionChange}
            />
            <SelectField
              label={t('labelForPlatform')}
              name="platform"
              value={platform}
              options={availablePlatforms}
              onChange={handlePlatformChange}
            />
          </div>
          {getSubmitButton()}
        </div>
      </div>
    </Fragment>
  );
};

export default DownloadProduct;
