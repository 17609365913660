/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Component of the Select Input.
 *
 *--------------------------------------------------------------------------------------------*/

import React from 'react';
import './styles/SelectField.css';

interface SelectFieldProps {
  label: string;
  name: string;
  value: string | undefined;
  options: { label: string; key: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectField: React.FC<SelectFieldProps> = ({ label, name, value, options, onChange }) => {
  return (
    <div className="select-field">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} value={value} onChange={onChange}>
        {options.map(option => (
          <option value={option.key} key={option.key}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;