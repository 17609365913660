/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 *
 *
 *--------------------------------------------------------------------------------------------*/

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import config from '../config';

interface AuthContextType {
    user: User | undefined;
    accessToken: string | undefined;
    isLogged: boolean;
    isAuthenticating: boolean;
    login: () => void;
    logout: () => void;
    handleCode: (code: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: React.ReactNode;
}

export interface User {
    name: string;
    email: string;
    avatar_url: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isLogged, setIsLogged] = useState<boolean>(false);

    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [accessToken, setAccessToken] = useState<string>();

    const [user, setUser] = useState<User>();

    const login = () => {
        const gitlabOAuthUrl = `${config.oauthProvider}/${config.authorizeUrl}?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&response_type=${config.responseType}&scope=${config.scope}&state=state`;
        window.location.href = gitlabOAuthUrl;
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setIsLogged(false);
        setAccessToken(undefined);
    };

    const handleCode = useCallback(async (code: string) => {
        const response = await fetch(`${config.authServer}/${config.tokenExchangeApi}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: code,
                grant_type: 'authorization_code',
                client_type: 'download',
                state: 'state',
            })
        });
        if (response.status === 200) {
            const result = await response.json();

            if (result.access_token && result.refresh_token) {
                localStorage.setItem('access_token', result.access_token);
                localStorage.setItem('refresh_token', result.refresh_token);
                setAccessToken(result.access_token);
            }
        }
    }, []);

    const validateToken = async (accessToken: string): Promise<boolean> => {
        const response = await fetch(`${config.authServer}/${config.tokenExchangeApi}?access_token=${accessToken}`);
        if (response.status === 200) {
            // const data = await response.json()
            return true;
        } else {
            return false;
        }
    }

    const fetchUserInfo = async (token: string) => {
        setIsAuthenticating(true);
        const response = await fetch(`${config.oauthProvider}/${config.userInfoUrl}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            const user: User = await response.json();

            setUser(user);
            setIsLogged(true);
        }

        setIsAuthenticating(false);
    };

    useEffect(() => {
        if (accessToken) {
            fetchUserInfo(accessToken);
        }
    }, [accessToken]);

    /** Get cached tokens */
    useEffect(() => {
        const checkTokens = async (cachedAccessToken: string, cachedRefreshToken: string) => {
            const isValidToken = await validateToken(cachedAccessToken);
            if (isValidToken) {
                setAccessToken(cachedAccessToken);
            } else {
                const response = await fetch(`${config.authServer}/${config.tokenExchangeApi}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        refresh_token: cachedRefreshToken,
                        grant_type: 'refresh_token',
                        client_type: 'download',
                        scope: 'read_user'
                    })
                });
                if (response.status === 200) {
                    const result = await response.json();

                    if (result.access_token && result.refresh_token) {
                        localStorage.setItem('access_token', result.access_token);
                        localStorage.setItem('refresh_token', result.refresh_token);
                        setAccessToken(result.access_token);
                    }
                }
            }
        }

        const cachedAccessToken = localStorage.getItem('access_token');
        const cachedRefreshToken = localStorage.getItem('refresh_token');
        if (cachedAccessToken && cachedRefreshToken) {
            checkTokens(cachedAccessToken, cachedRefreshToken);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ user, accessToken, isLogged, isAuthenticating, login, logout, handleCode }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
