/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 *
 *
 *--------------------------------------------------------------------------------------------*/

import env from './config/env.json';

export interface Config {
    oauthProvider: string;
    authorizeUrl: string;
    userInfoUrl: string;
    clientId: string;
    responseType: string;
    scope: string;
    redirectUri: string;
    authServer: string;
    tokenExchangeApi: string;
    downloadServer: string;
    availableVersionsApi: string;
    fileDownloadApi: string;
    freeFileDownloadApi: string;
    contactUsUrl: string;
    availablePacksApi: string;
    packDownloadApi: string;
}

const config: Config = env;

export default config;
