/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * i18n entry
 *
 *--------------------------------------------------------------------------------------------*/

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // Load translation files with http backend plugin
  .use(initReactI18next) // Initialize React i18next
  .init({
    lng: navigator.language, // Use default language of browser
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',  // Path to translation files
    },
  });

export default i18n;
