/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Navigator component.
 *
 *--------------------------------------------------------------------------------------------*/

import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../utils/AuthContext';
import logo from '../assets/terapines-logo-white.png';

import './styles/Navigator.css';

const Navigator: React.FC = () => {
    const { user, isLogged, login, logout } = useAuth();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [language, setLanguage] = useState<string>(i18n.language);

    const handleLanguageChange = () => {
        const currentLanguage = i18n.language;

        if (currentLanguage == 'en') {
            setLanguage('zh-CN');
            i18n.changeLanguage('zh-CN');
        } else {
            setLanguage('en');
            i18n.changeLanguage('en');
        }
    }

    return (
        <nav className="navbar">
            {/* TODO: 添加自适应效果 */}
            <div className="nav-menu">
                <Link to="/" className='home-logo'>
                    <img src={logo} alt="Company Logo" className="logo" />
                </Link>
                <button
                    className={location.pathname.includes('downloads') ? 'selected-menu menu' : 'menu'}
                    onClick={() => {navigate('/downloads')}}
                    title={t('downloadsMenu')}
                >
                    {t('downloadsMenu')}
                </button>
                <button
                    className={location.pathname.includes('docs') ? 'selected-menu menu' : 'menu'}
                    onClick={() => {navigate('/docs')}}
                    title={t('docsMenu')}
                >
                    {t('docsMenu')}
                </button>
            </div>
            <div className="nav-menu">
                {
                    language === 'en' ?
                    <span className="icon lang icon-en-cn" onClick={() => handleLanguageChange()} title={t('changeLanguage')}/> :
                    <span className="icon lang icon-cn-en" onClick={() => handleLanguageChange()} title={t('changeLanguage')}/>
                }
                <div className="divider" />
                {isLogged && user ? (
                    <div className="user-info">
                        <img src={user?.avatar_url} alt="User Avatar" className="user-avatar" />
                        <span className="user-name">{user?.name}</span>
                        <button onClick={logout}>{t('logout')}</button>
                    </div>
                ) : (
                    <button onClick={login} title={t('login')}>{t('login')}</button>
                )}
                <div className="divider" />
                <button>
                    <a href='https://www.terapines.com' style={{ color: 'white', textDecoration: 'none' }} title={t('backToOfficialWebsite')}>
                        {t('backToOfficialWebsite')}
                    </a>
                </button>
            </div>
        </nav>
    );
};

export default Navigator;
