/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Component of the LibraryCheckbox.
 *
 *--------------------------------------------------------------------------------------------*/

import { t } from 'i18next';
import React, { useState } from 'react';
import ResizableList from './ResizableList';
import './styles/LibraryCheckbox.css';

export interface LibraryCheckboxProps {
  id: string;
  label: string;
  type?: 'checkbox' | 'radio';
  name?: string;
  description?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  className?: string;
  details?: string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const LibraryCheckbox: React.FC<LibraryCheckboxProps> = ({
  id, label, type, name, description, checked, defaultChecked, className, details, onChange
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div key={id} className='library-checkbox-container'>
      <div className='library-checkbox-line'>
        <div>
          <input type={type ?? 'checkbox'}
              id={id}
              name={name}
              className={`library-checkbox ${className}`}
              key={`checkbox:${id}`}
              checked={checked}
              defaultChecked={typeof checked === 'undefined' ? defaultChecked : undefined}
              onChange={onChange}
          />
          <span>{label}</span>
          <span className='library-checkbox-description'>{description}</span>
        </div>
        {details &&
          <div
            className={`icon-detail ${expanded ? 'expanded' : ''}`}
            onClick={() => setExpanded(!expanded)}
            title={t('detail')}
          >
          </div>
        }
      </div>
      {details &&
        <div hidden={!expanded}>
          <ResizableList rows={details}/>
        </div>
      }
    </div>
  );
};
