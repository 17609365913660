/*---------------------------------------------------------------------------------------------
 *
 *  Copyright (C) 2020-2024 Terapines Technology (Wuhan) Co., Ltd
 *  All rights reserved.
 *
 * --------------------------------------------------------------------------------------------
 *
 * Component of the LibraryCheckboxList.
 *
 *--------------------------------------------------------------------------------------------*/

import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { LibraryCheckbox, LibraryCheckboxProps } from './LibraryCheckbox';
import './styles/LibraryCheckboxList.css';

interface LibraryCheckboxListProps {
  checkboxes: LibraryCheckboxProps[];
  /**
   * Whether multiple election is supported.
   */
  multiple: boolean;
  /**
   * This will be called after the `standard_group` checkboxes changed.
   * @param status list of selected states for the checkboxes
   * @returns Download link for the selected items.
   */
  onChange: (status: boolean[]) => string | undefined;
}

const LibraryCheckboxList: React.FC<LibraryCheckboxListProps> = ({ checkboxes, multiple, onChange }) => {
  const [all, setAll] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean[]>([]);
  const [downloadHref, setDownloadHref] = useState<string | undefined>('#');

  useEffect(() => {
    const newStatus = checkboxes.map(item => item.checked ?? false);
    setAll(!newStatus.some(item => item !== true));
    setStatus(newStatus);
    setDownloadHref(onChange(newStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes.length]);

  return (
    <div className='library-group-container'>
      <div className='library-group'>
        {multiple ?
          <LibraryCheckbox
            key='all'
            id='all'
            label={t('all')}
            checked={all}
            onChange={() => {
              const newValue = !all;
              const newStatus = status.map(() => newValue);
              setAll(newValue);
              setStatus(newStatus);
              setDownloadHref(onChange(newStatus));
            }}
          /> : <></>
        }
        <div className='library-checkbox-list'>
          {checkboxes.map((item, index) => {
            return (
              <LibraryCheckbox
                key={index}
                id={item.id}
                label={item.label}
                type={multiple ? 'checkbox' : 'radio'}
                details={item.details}
                checked={status[index] ?? false}
                defaultChecked={item.defaultChecked}
                description={item.description}
                onChange={e => {
                  item.onChange?.(e);
                  let newStatus = status;
                  if (!status[index] && !multiple) {
                    newStatus = status.map(() => false);
                  }
                  newStatus[index] = !status[index];
                  setStatus(newStatus);
                  setAll(!newStatus.some(item => item !== true));
                  setDownloadHref(onChange(newStatus));
                }}
              />
            );
          })}
        </div>
      </div>
        <a href={downloadHref} target="_blank" rel="noopener noreferrer" className="submit-a">
          <button type="submit" className="submit-button" disabled={!downloadHref}>
            {t('download')}
          </button>
        </a>
        <a href={downloadHref ? downloadHref + '&md5=true' : downloadHref} target="_blank" rel="noopener noreferrer" className="submit-a">
          <button type="submit" className="submit-button" disabled={!downloadHref}>
            {t('download-md5')}
          </button>
        </a>
    </div>
  );
};

export default LibraryCheckboxList;
